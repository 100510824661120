<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'

export default {
  // props: ['dataXAis', 'dataList','echartId'],
  props: {
    echartId: {
      type: String,
      default: function () {
        return 'echartId'
      }
    },
    showLegend: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showBorder: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    optionsTitle: {
      type: String,
      default: function () {
        return '23家\n本季度+3家'
      }
    },
    dataList: {
      type: Array,
      default: function () {
        return [
          { value: 40, name: '规模以上工业企业', color: '#149AFE' },
          {
            value: 38,
            name: '房地产企业和资质等级以上建筑',
            color: '#3BE8FF'
          },
          { value: 32, name: '限额以上批零住餐企业', color: '#F0DE4B' },
          { value: 30, name: '规模以上服务企业', color: '#36EDB5' }
        ]
      }
    }
  },
  data () {
    return {
      options: {
        legend: {
          top: 'bottom',
          textStyle: {
            color: '#D0DEEE'
          },
          itemWidth: 14,
          itemHeight: 14,
          show: this.showLegend
        },
        title: {
          show: false,
          text: this.optionsTitle,
          top: 'center',
          left: '49%',
          textAlign: 'center',
          textStyle: {
            color: 'white',
            fontSize: '14',
            lineHeight: 20
          },
          triggerEvent: true
        },
        tooltip: {
          trigger: 'item'
        },
        toolbox: {
          show: true
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '90%'],
            center: ['50%', '50%'],
            roseType: this.showBorder ? 'area' : '',
            // labelLine: {
            //   show: false,
            // },
            label: {
              show: false
              // position: 'center'
            },
            // itemStyle: {
            //   borderRadius: 8,
            // },
            color: ['#149AFE', '#3BE8FF', '#F0DE4B', '#36EDB5'],
            data: this.dataList
          }
        ]
      }
    }
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    dataList: function (val) {
      this.getShowPie()
    },
    optionsTitle: function (val) {
      // console.log(this.optionsTitle,"optionsTitle")
      // this.options.title.text = this.optionsTitle
      this.getShowPie()
    }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      myCharts.setOption({
        series:{
          data:this.dataList
        }
      })
      var echartsid = document.getElementById(this.echartId)
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.clear()
      myCharts.setOption(this.options)
      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
      // myCharts.getZr().on("click", (params) => {
      //   // console.log(params,"params")
      //   // 设置限制条件，确保只有点击的节点为title时才生效
      //   if (
      //     params.target &&
      //     params.target.eventData &&
      //     params.target.eventData.componentType === "title"
      //   ) {
      //     this.$emit("showModal", params.target);
      //   }
      // });
    }
  }
}
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
