<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from "@/utils/esresize.js";

export default {
  // props: ["dataXAis", "dataList", "echartId","legendPosition"],
  props: {
    legendPosition: {
      type: String,
      default: function () {
        return "auto";
      },
    },
    echartId: {
      type: String,
      default: function () {
        return "echartId";
      },
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    centerPosition: {
      type: Array,
      default: function () {
        return ["50%", "50%"];
      },
    },
    dataList: {
      type: Array,
      default: function () {
        return [
              { value: 1048, name: "30亿以内" },
              { value: 735, name: "30亿级" },
              { value: 580, name: "100亿级" },
              { value: 484, name: "200亿级" },
              { value: 300, name: "500亿级" },
              { value: 300, name: "1000亿级" },
            ];
      },
    },
    showType: {
      type: String,
      default: () => "All",
    },
    optionsTitle: {
      type: String,
      default: function () {
        return "500家\n220亿元";
      },
    },
  },
  data() {
    return {
      options: {
        legend: {
          show: this.showLegend,
          top: "bottom",
          right: this.legendPosition,
          textStyle: {
            color: "#D0DEEE",
          },
          itemWidth: 14,
          itemHeight: 14,
          // orient: "horizontal",
        },
        // title: {
        //   text: this.optionsTitle,
        //   top: "center",
        //   left: "49%",
        //   textAlign: "center",
        //   textStyle: {
        //     color: "white",
        //     fontSize: "14",
        //     lineHeight: 20,
        //   },
        //   triggerEvent: true 
        // },
        tooltip: {
          trigger: "item",
        },
        toolbox: {
          show: true,
        },
        series: [
          // {
          //   type: "pie",
          //   radius: ["40%", "70%"],
          //   center: ["50%", "38%"],
          //   roseType: "area",
          //   // labelLine: {
          //   //   show: false,
          //   // },
          //   label: {
          //     // show: false,
          //     // position: 'center'
          //   },
          //   // itemStyle: {
          //   //   borderRadius: 8,
          //   // },
          //   color: ["#149AFE", "#3BE8FF", "#F0DE4B", "#36EDB5"],
          //   data: [
          //     { value: 40, name: "规模以上工业企业", color: "#149AFE" },
          //     {
          //       value: 38,
          //       name: "房地产企业和资质等级以上建筑",
          //       color: "#3BE8FF",
          //     },
          //     { value: 32, name: "限额以上批零住餐企业", color: "#F0DE4B" },
          //     { value: 30, name: "规模以上服务企业", color: "#36EDB5" },
          //   ],
          // },
          {
            // name: 'Access From',
            type: "pie",
            radius: ["50%", "90%"],
            center: this.centerPosition,
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#0f3753",
              borderWidth: 2,
            },
            labelLine: {
              show: false,
            },
            // label: {
            //   show: false,
            //   position: "center",
            // },
            // roseType: "area",
            label: {
              show: true,
              position: "inner",
              fontSize: "12",
              formatter: (params) => {
                // console.log(params,"params")
                if (this.showType == "All") {
                  return params.percent + "%";
                } else {
                  if (this.showType == params.name) {
                    return params.percent + "%";
                  } else {
                    return "";
                  }
                }
              },
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "14",
            //     fontWeight: "bold",
            //   },
            //   labelLine: {
            //     show: true,
            //   },
            // },
            color: [
              "#149AFE",
              "#4EC8D3",
              "#E1D14F",
              "#46CCA3",
              "#7963FF",
              "#D55AF8",
            ],
            data:this.dataList ,
          },
        ],
      },
    };
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // },
    showType: function (val) {
      // console.log(this.optionsTitle,"optionsTitle")
      // this.options.title.text = this.optionsTitle
      this.getShowPie()
    }
  },
  mounted() {
    // setTimeout(() => {
    this.getShowPie();
    // }, 1000)
  },
  methods: {
    getShowPie() {
      // this.options.xAxis.data = this.dataXAis
      // this.options.series[0].data = this.dataList;
      var myCharts = this.$echarts.init(document.getElementById(this.echartId));
      var echartsid = document.getElementById(this.echartId);
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.clear()
      myCharts.setOption(this.options);
      let listener = function () {
        myCharts.resize();
      };
      EleResize.on(echartsid, listener);
      myCharts.getZr().on("click", (params) => {
        // 设置限制条件，确保只有点击的节点为title时才生效
        if (
          params.target &&
          params.target.eventData &&
          params.target.eventData.componentType === "title"
        ) {
          this.$emit("showModal", params.target);
        }
      });
    },
  },
};
</script>

<style>
.echart-main {
  height: 100%;
  width: 100%;
}
</style>
