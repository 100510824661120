<template>
  <div class="IndustryDistributionMap">
    <el-container>
      <el-aside class="left_aside">
        <div class="left_top">
          <chart-Common :isShowHeader="false">
            <div class="top_content">
              <div class="nav">
                <img
                  src="../assets/images/IndustryDistributionMap/left/yuan@2x.png"
                  alt=""
                />
                <span
                  class="nav_top"
                  @click="selectNav('top')"
                  :style="selectedNav == 'top' ? 'font-weight:bold' : ''"
                  >坪山各类企业</span
                >
                <span
                  class="nav_bottom"
                  @click="selectNav('bottom')"
                  :style="selectedNav == 'bottom' ? 'font-weight:bold' : ''"
                  >高新</span
                >
                <span
                  class="nav_right"
                  @click="selectNav('right')"
                  :style="selectedNav == 'right' ? 'font-weight:bold' : ''"
                  >四上</span
                >
                <span
                  class="nav_left"
                  @click="selectNav('left')"
                  :style="selectedNav == 'left' ? 'font-weight:bold' : ''"
                  >专精特新</span
                >
                <img
                  src="../assets/images/IndustryDistributionMap/left/xia@2x.png"
                  alt=""
                  class="bottom_selected"
                  v-show="selectedNav == 'bottom'"
                />
                <img
                  src="../assets/images/IndustryDistributionMap/left/zuo@2x.png"
                  alt=""
                  class="left_selected"
                  v-show="selectedNav == 'left'"
                />
                <img
                  src="../assets/images/IndustryDistributionMap/left/shang@2x.png"
                  alt=""
                  class="top_selected"
                  v-show="selectedNav == 'top'"
                />
                <img
                  src="../assets/images/IndustryDistributionMap/left/you@2x.png"
                  alt=""
                  class="right_selected"
                  v-show="selectedNav == 'right'"
                />
              </div>
              <div class="text-item-container">
                <div class="item-container">
                  <div
                    class="text-item"
                    v-for="(item, index) in navMenuList"
                    :key="index"
                  >
                    <div class="title-container">
                      <span class="title-text">{{ item.name }}</span>
                      <span class="bg"></span>
                    </div>
                    <div class="num-container">
                      <span class="num">{{ item.num }}</span>
                      <span>家</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line-container">
                <i class="line"></i>
              </div>
            </div>
          </chart-Common>
        </div>
        <!-- <div class="left_bott">
          <chart-Common :isShowHeader="false">
            <div class="bottom_content">
              <pie-Chart echartId="pie1"></pie-Chart>
            </div>
          </chart-Common>
        </div> -->
      </el-aside>
      <el-main>
        <!-- <Map ref="map" :type="industryType"></Map> -->
        <div id="map"></div>
      </el-main>
      <el-aside class="right_aside">
        <div class="right_top">
          <div class="data-num-container">
            <div class="data-num-item">
              <span class="data-lebel">企业数量</span>
              <span class="data-num"><i>112022</i>家</span>
            </div>
            <div class="data-num-item">
              <span class="data-lebel">工业园区</span>
              <span class="data-num"><i>799</i>家</span>
            </div>
          </div>
          <div class="select-container">
            <div class="left-container">
              <span class="date-container">2022</span>
              <div class="select-op-container">
                <el-select
                  v-model="value"
                  placeholder="请选择"
                  @change="changeQuarter"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="right-container">
              <span class="label">重大项目</span>
              <span
                ><i>{{ projectNum }}</i
                >个</span
              >
            </div>
          </div>
          <div class="chart-one-container">
            <chart-Common title="2021年三大主导产业">
              <div class="chart-one">
                <div class="chart-one-top">
                  <div class="top-left">
                    <div class="img-icon-container">
                      <img src="@/assets/images/charts/tongji.png" alt="" />
                      <span>总统计</span>
                    </div>
                    <div class="text-container">
                      <span>400</span>
                      家
                    </div>
                  </div>
                  <div class="top-right">
                    <div class="top-container">
                      <span>迁入（家）</span>
                      <i></i>
                    </div>
                    <div class="bottom-container">
                      <span>+2</span>
                      <img
                        class="arrow"
                        src="@/assets/images/charts/redarrow.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="chart-one-bottom">
                  <div class="chart-main-container">
                    <pieChart
                      :dataList="pieChartSelect"
                      :showLegend="false"
                      ref="chartOne"
                      :echartId="'chartOne'"
                      @showModal="initModal"
                      :optionsTitle="allChartLegend.chartA.optionsTitle"
                    ></pieChart>
                    <div class="chart-title-container">
                      <span class="money">{{ chartCTitle.num }}亿元</span>
                      <span>同比增长</span>
                      <span class="num-text"
                        >{{ chartCTitle.changeNum + '%' }}
                        <i class="arrow-img-container">
                          <img
                            class="arrow"
                            src="@/assets/images/charts/redarrow.png"
                            alt=""
                        /></i>
                      </span>
                    </div>
                  </div>
                  <div class="fourth-charLegend">
                    <div class="legend-item-container">
                      <div class="legend-item special-legend-item">
                        <span
                          @click="legendSelect('chartA', { text: 'All' })"
                          class="label-text"
                          :class="{
                            current:
                              allChartLegend.chartA.currentChartSelect == 'All'
                          }"
                          >全部</span
                        >
                      </div>
                      <div
                        class="legend-item"
                        v-for="(item, index) in pieChartSelect"
                        :key="index"
                        @click="legendSelect('chartA', item)"
                      >
                        <i class="rect" :style="{ background: item.color }"></i>
                        <span
                          class="label-text"
                          :class="{
                            current:
                              allChartLegend.chartA.currentChartSelect ==
                              item.text
                          }"
                          >{{ item.text }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </chart-Common>
          </div>
          <div class="chart-two-container">
            <chart-Common title="专利">
              <div class="chart-two">
                <div class="chart-two-top">
                  <div class="top-top">
                    <div class="img-icon-container">
                      <img src="@/assets/images/charts/tongji.png" alt="" />
                      <span>全区专利</span>
                    </div>
                    <div class="text-container">
                      <span>9275</span>
                    </div>
                  </div>
                  <div class="top-bottom">
                    <div class="bottom-item">
                      <div class="top-container">
                        <span>发明专利</span>
                        <i></i>
                      </div>
                      <div class="bottom-container">
                        <span>1251</span>
                      </div>
                    </div>
                    <div class="bottom-item">
                      <div class="top-container">
                        <span>实用新型专利</span>
                        <i></i>
                      </div>
                      <div class="bottom-container">
                        <span>5341</span>
                      </div>
                    </div>
                    <div class="bottom-item">
                      <div class="top-container">
                        <span>外观设计专利</span>
                        <i></i>
                      </div>
                      <div class="bottom-container">
                        <span>1725</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chart-two-bottom">
                  <div class="chart-main-container">
                    <pieChart
                      :dataList="pieChartSelectB"
                      :showLegend="false"
                      :echartId="'chart-two'"
                      @showModal="initModal"
                      :optionsTitle="allChartLegend.chartB.optionsTitle"
                      :showBorder="false"
                    ></pieChart>
                    <!-- <div class="chart-title-container">
                      <span class="money">{{ chartCTitle.num }}亿元</span>
                      <span>同比增长</span>
                      <span class="num-text"
                        >{{ chartCTitle.changeNum + "%" }}
                        <i class="arrow-img-container">
                          <img
                            class="arrow"
                            src="@/assets/images/charts/redarrow.png"
                            alt=""
                        /></i>
                      </span>
                    </div> -->
                  </div>
                  <div class="fourth-charLegend">
                    <div class="legend-item-container">
                      <div class="legend-item special-legend-item">
                        <span
                          @click="legendSelect('chartB', { text: 'All' })"
                          class="label-text"
                          :class="{
                            current:
                              allChartLegend.chartB.currentChartSelect == 'All'
                          }"
                          >全部</span
                        >
                      </div>
                      <div
                        class="legend-item"
                        v-for="(item, index) in pieChartSelectB"
                        :key="index"
                        @click="legendSelect('chartB', item)"
                      >
                        <i class="rect" :style="{ background: item.color }"></i>
                        <span
                          class="label-text"
                          :class="{
                            current:
                              allChartLegend.chartB.currentChartSelect ==
                              item.text
                          }"
                          >{{ item.text }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </chart-Common>
          </div>
        </div>
      </el-aside>
    </el-container>
  </div>
</template>
<script>
const cityOptions = [
  '平湖',
  '宝龙',
  '坪地',
  '坂田',
  '园山',
  '南湾',
  '龙岗',
  '龙城',
  '横岗',
  '吉华',
  '布吉'
]
// import Map from '@/components/map'
import L from 'leaflet'
import '@supermap/iclient-leaflet'
import 'leaflet-editable'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import chartCommon from '@/components/chartCommon/index'
import pieChart from '@/components/charts/pie'
import pieBChart from '@/components/charts/pieB'
export default {
  components: {
    // Map,
    chartCommon,
    pieChart,
    pieBChart
  },
  data () {
    return {
      map:null,
      //  =============================坪山====================================
      map_gaoxin:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/gxjsqy@ps`,
      map_sishang:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/gyqy@ps`,
      map_zjtx1:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/zjtx_gd@ps`,
      map_zjtx2:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/zjtx_gj@ps`,
      map_glqy1:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_jsyj@ps`,
      map_glqy2:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_gjcp@ps`,
      map_glqy5:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_qyjs@ps`,
      map_glqy3:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_gcjs@ps`,
      map_glqy4:
        `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/glqy_gcyj@ps`,
      projectNum: 23,
      selectedNav: 'bottom',
      industryType: '0',
      checkAll: false,
      checkedCities: ['上海', '北京'],
      cities: cityOptions,
      isIndeterminate: true,
      navMenuList: [
        { name: '电子信息技术', num: 190 },
        { name: '生物与新医药技术', num: 123 },
        { name: '新材料技术', num: 80 },
        { name: '高技术服务业', num: 21 },
        { name: '新能源及节能技术', num: 34 },
        { name: '资源与环境技术', num: 29 },
        { name: '先进制造与自动化', num: 209 }
      ],
      leftList: [
        { name: '国家级专精特新小巨人企业名单', num: 9 },
        { name: '广东省专精特新企业名单', num: 123 }
      ],
      rightList: [
        { name: '规上工业', num: 703 },
        { name: '房地产企业和资质等级以上建筑业', num:'--' },
        { name: '限额以上批零住餐', num:'--' },
        { name: '规模以上服务业', num:'--' },
        ],
      bottomList: [
        { name: '电子信息技术', num: 190 },
        { name: '生物与新医药技术', num: 123 },
        { name: '新材料技术', num: 80 },
        { name: '高技术服务业', num: 21 },
        { name: '新能源及节能技术', num: 34 },
        { name: '资源与环境技术', num: 29 },
        { name: '先进制造与自动化', num: 209 }
      ],
      topList: [
        { name: '国家单项冠军产品企业名单', num: 4 },
        { name: '国家级企业技术中心', num: 7 },
        { name: '国家级众创空间', num: 1 },
        { name: '国家级孵化器', num: 1 },
        { name: '深圳技术大学校企合作研究院清单', num: 12 },
        { name: '广东省工程技术研究中心（省科创委主管）', num: 47 },
        { name: '深圳市工程技术中心（市科创委主管）', num: 8 },
        { name: '深圳市工程研究中心（市发改委主管）', num: 13 },
        { name: '深圳市企业技术中心（市工信局主管）', num: 15 },
        { name: '坪山区重点研发平台名单', num: 6 },
        { name: '比亚迪集团研发机构', num: 11 }
      ],
      options: [
        {
          label: '一季度',
          value: 1
        },
        {
          label: '二季度',
          value: 2
        }
      ],
      value: '一季度',
      allChartLegend: {
        chartA: {
          currentChartSelect: 'All',
          optionsTitle: '+500亿元\n同比+12%'
        },
        chartB: {
          currentChartSelect: 'All',
          optionsTitle: '300家\n120亿元'
        },
        chartC: {
          currentChartSelect: 'All',
          optionsTitle: '+500亿元\n同比+12%'
        },
        chartD: {
          currentChartSelect: 'All',
          optionsTitle: '23家\n本季度+3家'
        }
      },
      pieChartSelect: [
        {
          value: 744,
          color: '#149AFE',
          name: '绿色低碳产业产值',
          text: '绿色低碳产业产值',
          percent: '23.80'
        },
        {
          value: 520.3,
          color: '#4EC8D3',
          name: '新一代信息技术增长产值',
          text: '新一代信息技术增长产值',
          percent: '11.70'
        },
        {
          value: 210.26,
          color: '#E1D14F',
          name: '生物医药和健康产业产值',
          text: '生物医药和健康产业产值',
          percent: '1.50'
        }
      ],
      pieChartSelectB: [
        {
          color: '#149AFE',
          text: '发明专利',
          name: '发明专利',
          value: '1251'
        },
        {
          color: '#4EC8D3',
          text: '实用新型专利',
          name: '实用新型专利',
          value: '5341'
        },
        {
          color: '#E1D14F',
          text: '外观设计专利',
          name: '外观设计专利',
          value: '1725'
        }
      ],
      chartCTitle: {
        num: '1474.6',
        changeNum: '+15.80'
      },
      sishang: '',
      gaoxin: '',
      resultLayer: '',
      querygroup: '',
      querygroup2: '',
      zjtxgroupLayer: '',
      glqygroupLayer: '',
      cluster: '',
      value2: '',
      jiedao: '',
      yuanqu: '',
      build: ''
    }
  },
  created () {
    this.changeQuarter(1)
  },
  mounted () {
    this.init()
    this.selectNav('bottom')

  },
  methods: {
    initModal() {},
    init () {
      const vm = this
      // eslint-disable-next-line no-undef
      var tianditu = L.supermap.tiandituTileLayer({
        layerType: 'img',
        url:
          'http://t0.tianditu.gov.cn/img_c/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var labelMap = L.supermap.tiandituTileLayer({
        isLabel: true,
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var tdtYx = L.layerGroup([tianditu, labelMap])
      // var qu = L.supermap.tiledMapLayer(this.map_qu)
      this.jiedao = L.supermap.tiledMapLayer(this.map_jd)
      this.yuanqu = L.supermap.tiledMapLayer(this.map_yuanqu)
      this.build = L.supermap.tiledMapLayer(this.map_build)
      this.gaoxin = L.supermap.tiledMapLayer(this.map_gaoxin)
      this.sishang = L.supermap.tiledMapLayer(this.map_sishang)
      var zjtx1 = L.supermap.tiledMapLayer(this.map_zjtx1)
      var zjtx2 = L.supermap.tiledMapLayer(this.map_zjtx2)
      var glqy1 = L.supermap.tiledMapLayer(this.map_glqy1)
      var glqy2 = L.supermap.tiledMapLayer(this.map_glqy2)
      var glqy3 = L.supermap.tiledMapLayer(this.map_glqy3)
      var glqy4 = L.supermap.tiledMapLayer(this.map_glqy4)
      var glqy5 = L.supermap.tiledMapLayer(this.map_glqy5)
      this.map = L.map('map', {
        preferCanvas: true,
        crs: L.CRS.EPSG4326,
        center: [22.7152291384486,114.347931662042],
        // center: [[22.7152291384486,114.347931662042]],
        maxZoom: 16,
        zoom: 11,
        // layers: [tdtYx, qujie,gaoxin],
        layers: [tdtYx],
        zoomControl: false,
        control: ['zoomControl'],
        //  maxBounds: [
        //   [ 22.65, 114.27],
        //   [22.78 , 114.35167101400009]
        // ]
      })
      this.querygroup = L.layerGroup().addTo(this.map)
      this.gaoxin.addTo(this.querygroup)
      // L.control.layers(baseMaps, overlayMaps).addTo(this.map)
      // L.marker([22.7152291384486,114.347931662042]).addTo(this.map)
      this.querygroup2 = L.layerGroup().addTo(this.map)
      this.zjtxgroupLayer = L.layerGroup([zjtx1, zjtx2])
      this.glqygroupLayer = L.layerGroup([glqy1, glqy2, glqy3, glqy4, glqy5])
      this.map.on('click', function (evt) {
        vm.x = evt.latlng.lng
        vm.y = evt.latlng.lat
        vm.latlng = evt.latlng
        // vm.Sqlquery()
      })
    },
    changeData (name) {
      if (this.map.hasLayer(this.querygroup)) {
         this.querygroup.clearLayers()
      }
      if (this.map.hasLayer(this.zjtxgroupLayer)) {
        this.map.removeLayer(this.zjtxgroupLayer)
      }
      if (this.map.hasLayer(this.glqygroupLayer)) {
        this.map.removeLayer(this.glqygroupLayer)
      }
     
      // this.zjtxgroupLayer.clearLayers()
      // this.glqygroupLayer.clearLayers()
      switch (name) {
        case 'gaoxin':
          // if(this.map.hasLayer(this.yuanqu)) {
          //   this.map.removeLayer(this.yuanqu)
          // }

          this.gaoxin.addTo(this.querygroup).addTo(this.map)
          // this.jiedao.addTo(this.map)
          break
        case 'sishang':
          this.sishang.addTo(this.querygroup).addTo(this.map)
          break
        case 'zjtx':
          this.zjtxgroupLayer.addTo(this.map).addTo(this.map)
          break
        case 'glqy':
          this.glqygroupLayer.addTo(this.map).addTo(this.map)
          break

        default:
          break
      }
    },
    selectNav (val) {
      this.selectedNav = val
      switch (val) {
        case 'top':
          this.navMenuList = this.topList
          this.changeData('glqy')
          break
        case 'bottom':
          this.navMenuList = this.bottomList
          this.changeData('gaoxin')
          break
        case 'left':
          this.navMenuList = this.leftList
          this.changeData('zjtx')
          break
        case 'right':
          this.navMenuList = this.rightList
          this.changeData('sishang')
          break

        default:
          break
      }
    },
    changeQuarter (val) {
      if (val == 1) {
        this.projectNum = 23
      } else if (val == 2) {
        this.projectNum = 15
      }
    },
    handleCheckAllChange (val) {
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    },
    legendSelect (chart, item) {
      // console.log('66444')
      var ranDomNum = Math.ceil(Math.random(100) * 100)
      if (chart == 'chartA') {
        if (item.text == 'All') {
          this.chartCTitle = {
            num: '+' + 1474.6,
            changeNum: '+' + 15.8
          }
          this.allChartLegend[
            chart
          ].optionsTitle = `+${ranDomNum}亿元\n同比+${ranDomNum}%`
        } else {
          this.chartCTitle = {
            num: '+' + item.value,
            changeNum: '+' + item.percent
          }
          this.allChartLegend[
            chart
          ].optionsTitle = `-${ranDomNum}亿元\n同比-${ranDomNum}%`
        }
      }
      if (chart == 'chartB') {
        // if (ranDomNum > 60) {
        //   this.chartCTitle = {
        //     num: '+' + ranDomNum,
        //     changeNum: '+' + ranDomNum
        //   }
        //   this.allChartLegend[
        //     chart
        //   ].optionsTitle = `+${ranDomNum}亿元\n同比+${ranDomNum}%`
        // } else {
        //   this.chartCTitle = {
        //     num: '-' + ranDomNum,
        //     changeNum: '-' + ranDomNum
        //   }
        //   this.allChartLegend[
        //     chart
        //   ].optionsTitle = `-${ranDomNum}亿元\n同比-${ranDomNum}%`
        // }
      }

      this.allChartLegend[chart].currentChartSelect = item.text
    }
  }
}
</script>
<style lang="less" scoped>
#map {
  width: 100%;
  height: 100%;
}
.IndustryDistributionMap {
  padding: 0 1.3125rem;
  height: 100%;
}

.top_selected {
  width: 8.125rem !important;
  height: 3.25rem !important;
  position: absolute;
  top: 2.4375rem;
  left: 6.625rem;
  z-index: 8;
}
.bottom_selected {
  width: 8.125rem !important;
  height: 3.25rem !important;
  position: absolute;
  bottom: 2.4375rem;
  left: 6.625rem;
  z-index: 8;
}
.left_selected {
  width: 3.3125rem !important;
  height: 8.125rem !important;
  position: absolute;
  bottom: 4.3125rem;
  left: 4.6875rem;
  z-index: 8;
}
.right_selected {
  width: 3.3125rem !important;
  height: 8.125rem !important;
  position: absolute;
  bottom: 4.3125rem;
  right: 4.375rem;
  z-index: 8;
}
.el-container {
  height: 100%;
}
.left_aside {
  width: 21.125rem /* 338/16 */ !important;
  height: 100% !important;
  .left_top {
    height: 100%;
  }
  .top_content {
    // height: 562px;
    height: 100%;
    padding: 20px 0;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    position: relative;
    .nav {
      text-align: center;
      position: relative;
      img {
        // width: 259px;
        // height: 259px;
        width: 16.1875rem /* 259/16 */;
        height: 16.1875rem /* 259/16 */;
      }
      span {
        cursor: pointer;
        font-size: 0.875rem;
        font-family: Source Han Sans CN;
        color: #f2fdff;
        z-index: 10;
        position: absolute;
      }
      .nav_top {
        left: 8.125rem /* 130/16 */ /* 155/16 */;
        top: 3.125rem /* 50/16 */;
      }
      .nav_bottom {
        left: 9.6875rem /* 155/16 */;
        bottom: 3.125rem /* 50/16 */;
      }
      .nav_left {
        left: 4.375rem /* 70/16 */;
        top: 7.5rem /* 120/16 */;
        transform: rotate(-90deg);
      }
      .nav_right {
        left: 14.4375rem /* 231/16 */ /* 155/16 */;
        bottom: 7.6875rem /* 123/16 */ /* 50/16 */;
        transform: rotate(90deg);
      }
    }
    .text-item-container {
      width: 100%;
      // height: calc(100% - 16.1875rem);
      // height: 34.375rem;
      height: 42rem;
      // padding: 0 1.25rem;
      padding-left: 1.25rem;
      padding-top: 1.25rem;
      overflow: hidden;
      .item-container {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        .text-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;
          .title-container {
            position: relative;
            display: flex;
            align-items: center;
            border-left: 4px solid #3be8ff;
            .title-text {
              font-size: 1rem;
              z-index: 1;
              color: #d0deee;
              padding-left: 0.625rem;
              letter-spacing: 1.2px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .bg {
              position: absolute;
              // left: 0;
              // top: 0;
              height: 0.625rem;
              width: 100%;
              background: linear-gradient(
                90deg,
                #2c6794 0%,
                rgba(44, 103, 148, 0) 100%
              );
            }
          }
          .num-container {
            display: flex;
            align-items: center;
            padding-left: 0.625rem;
            margin-top: 0.625rem;
            span {
              font-size: 0.875rem;
              font-weight: 400;
              color: #d0deee;
              &.num {
                color: #ffffff;
                font-size: 1.25rem;
                margin-right: 0.3125rem;
              }
            }
          }
        }
      }
    }
    .line-container {
      position: absolute;
      bottom: 1.25rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .line {
      width: 16.6875rem;
      height: 0.125rem;
      background: #159aff;
      border-radius: 50%;
      display: inline-block;
      box-shadow: 1px 1px 4px #159aff;
    }
  }
  .left_bott {
    margin-top: 20px;
    height: 296px;
  }
  .bottom_content {
    height: 274px;
  }
}
.right_aside {
  width: 27.5rem /* 440/16 */ !important;
  margin-left: 0.625rem;
  .right_top {
    .data-num-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .data-num-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.75rem;
        width: 13.4375rem;
        height: 3.25rem;
        background: #0f3753;
        border: 1px solid #159aff;
        border-width: 1px 2px 1px 2px;
        border-left-color: #3be8ff;
        border-right-color: #3be8ff;
        box-shadow: 0px 6px 18px 0px rgba(21, 154, 255, 0.9) inset;
        i {
          font-style: normal;
          font-size: 1.25rem;
          font-weight: 400;
          color: #3be8ff;
          margin-right: 0.625rem;
        }
        .data-lebel {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
        }
        .data-num {
          font-size: 0.875rem;
          font-weight: 400;
          color: #d0deee;
        }
      }
    }
    .select-container {
      margin-top: 0.625rem;
      margin-bottom: 1.25rem;
      width: 100%;
      height: 3.125rem;
      background: #0f3753;
      border: 1px solid #159aff;
      box-shadow: 0px 6px 18px 0px rgba(21, 154, 255, 0.9) inset;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-container {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.25rem;
        .date-container {
          font-size: 0.875rem;
          font-weight: 400;
          color: #ffffff;
        }
        .el-select {
          width: 7.5rem;
          height: 1.75rem;
        }
      }
      .right-container {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.25rem;
        .label {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
        }
        span {
          font-size: 0.875rem;
          font-weight: 400;
          color: #d0deee;
          i {
            font-style: normal;
            font-size: 1.25rem;
            font-weight: 400;
            color: #3be8ff;
            margin-right: 0.625rem;
          }
        }
      }
    }

    .chart-one {
      height: 20rem;
      display: flex;
      flex-direction: column;
      margin-top: 1.25rem;
      padding: 0 1.25rem;
      .chart-one-top {
        height: 3.125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .top-left {
          background: rgba(86, 130, 163, 0.5);
          // border: 1px solid #159aff;
          // box-shadow: 0px 0px 16px 0px #159aff inset;
          height: 100%;
          width: 17.75rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1.25rem;
          border-left: 2px solid #3be8ff;
          border-right: 2px solid #3be8ff;
          .img-icon-container {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            img {
              height: 3.25rem;
              width: 3.25rem;
            }
          }
          .text-container {
            font-size: 0.875rem;
            // font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            span {
              font-size: 1.25rem;
              // font-family: PangMenZhengDao;
              font-weight: 400;
              color: #3be8ff;
            }
          }
        }
        .top-right {
          width: 6.5625rem;
          margin-left: 0.625rem;
          display: flex;
          flex-direction: column;
          .top-container {
            position: relative;
            display: flex;
            align-items: center;
            span {
              font-size: 0.8125rem;
              // font-family: Microsoft YaHei;
              font-weight: 400;
              color: #d0deee;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
            }
            i {
              width: 100%;
              height: 0.375rem;
              background: #5682a3;
              opacity: 0.5;
            }
          }
          .bottom-container {
            height: 1.75rem;
            background: rgba(86, 130, 163, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.625rem;
            span {
              font-size: 1rem;
              // font-family: PangMenZhengDao;
              font-weight: 400;
              color: #fffbf1;
            }
            img {
              width: 0.375rem;
              height: 1rem;
              margin-left: 0.625rem;
            }
          }
        }
      }
      .chart-one-bottom {
        flex-grow: 1;
        width: 100%;
        display: flex;
        align-items: center;
        .chart-main-container {
          width: 50%;
          height: 100%;
          position: relative;
        }
        .fourth-charLegend {
          // padding-bottom: 0.625rem;
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;

          justify-content: center;
          .legend-item-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            .legend-item {
              padding-left: 1.25rem;
              display: flex;
              align-items: center;
              margin-bottom: 0.3125rem;
              cursor: pointer;
              &.special-legend-item {
                width: 100%;
              }
              .rect {
                background: #149afe;
                height: 0.625rem;
                min-width: 0.625rem;
                margin-right: 0.25rem;
                display: inline-block;
              }
              .label-text {
                color: #d0deee;
                font-size: 0.875rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.current {
                  color: #159aff;
                }
              }
            }
          }
        }
      }
      .chart-title-container {
        // background: red;
        height: 6.25rem;
        width: 6.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -3.125rem;
        margin-left: -3.125rem;
        background: transparent;
        // background: red;
        cursor: pointer;
        z-index: 1;
        border-radius: 50%;
        span {
          margin: 0.125rem 0;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.875rem;
          font-weight: 400;
          color: #d0deee;
          &.money {
            font-size: 1rem;
            font-weight: 400;
            color: #149afe;
          }
          &.num-text {
            font-size: 1rem;
            font-weight: 400;
            color: #fffbf1;
          }
          .arrow-img-container {
            display: inline-block;
            width: 0.375rem;
            height: 1rem;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    .chart-two-container {
      margin-top: 1.25rem;

      .chart-two {
        height: 24.375rem;
        padding: 0 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .chart-two-top {
          margin-top: 1.25rem;
          display: flex;
          flex-direction: column;
          .top-top {
            background: rgba(86, 130, 163, 0.5);
            // border: 1px solid #159aff;
            // box-shadow: 0px 0px 16px 0px #159aff inset;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.25rem;
            border-left: 2px solid #3be8ff;
            border-right: 2px solid #3be8ff;
            .img-icon-container {
              display: flex;
              align-items: center;
              font-size: 0.875rem;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              img {
                height: 3.25rem;
                width: 3.25rem;
              }
            }
            .text-container {
              font-size: 0.875rem;
              // font-family: Source Han Sans CN;
              font-weight: 400;
              color: #d0deee;
              span {
                font-size: 1.25rem;
                // font-family: PangMenZhengDao;
                font-weight: 400;
                color: #3be8ff;
              }
            }
          }
          .top-bottom {
            margin-top: 0.625rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bottom-item {
              width: 30%;
              // margin-left: 0.625rem;
              display: flex;
              flex-direction: column;
              .top-container {
                position: relative;
                display: flex;
                align-items: center;
                span {
                  font-size: 0.8125rem;
                  // font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #d0deee;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                }
                i {
                  width: 100%;
                  height: 0.375rem;
                  background: #5682a3;
                  opacity: 0.5;
                }
              }
              .bottom-container {
                height: 1.75rem;
                background: rgba(86, 130, 163, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.625rem;
                span {
                  font-size: 1rem;
                  // font-family: PangMenZhengDao;
                  font-weight: 400;
                  color: #fffbf1;
                }
                img {
                  width: 0.375rem;
                  height: 1rem;
                  margin-left: 0.625rem;
                }
              }
            }
          }
        }
        .chart-two-bottom {
          flex-grow: 1;
          width: 100%;
          display: flex;
          align-items: center;
          .chart-main-container {
            width: 50%;
            height: 100%;
            position: relative;
          }
          .fourth-charLegend {
            // padding-bottom: 0.625rem;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;

            justify-content: center;
            .legend-item-container {
              display: flex;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              .legend-item {
                padding-left: 1.25rem;
                display: flex;
                align-items: center;
                margin-bottom: 0.3125rem;
                cursor: pointer;
                &.special-legend-item {
                  width: 100%;
                }
                .rect {
                  background: #149afe;
                  height: 0.625rem;
                  min-width: 0.625rem;
                  margin-right: 0.25rem;
                  display: inline-block;
                }
                .label-text {
                  color: #d0deee;
                  font-size: 0.875rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  &.current {
                    color: #159aff;
                  }
                }
              }
            }
          }
        }
        .chart-title-container {
          // background: red;
          height: 6.25rem;
          width: 6.25rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -3.125rem;
          margin-left: -3.125rem;
          background: transparent;
          // background: red;
          cursor: pointer;
          z-index: 1;
          border-radius: 50%;
          span {
            margin: 0.125rem 0;
            text-align: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.875rem;
            font-weight: 400;
            color: #d0deee;
            &.money {
              font-size: 1rem;
              font-weight: 400;
              color: #149afe;
            }
            &.num-text {
              font-size: 1rem;
              font-weight: 400;
              color: #fffbf1;
            }
            .arrow-img-container {
              display: inline-block;
              width: 0.375rem;
              height: 1rem;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.select-op-container {
  width: 7.5rem;
  height: 1.75rem;
  .el-select {
    width: 100%;
    height: 100%;
    .el-input {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        color: #fefeff;
        background: #103552;
        border: 1px solid #159aff;
      }
      .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
}
.el-select-dropdown {
  .el-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>
